<template>
  <div id="app">
    <router-view></router-view>
    <footer-page/>
  </div>
</template>

<script>
import FooterPage from "@/components/FooterPage";

export default {
  name: 'App',
  components: {
    FooterPage,
  },
  metaInfo() {
    return {
      title: "Dark Angel Eventi",
      meta: [
        { name: 'description', content:  'Il sound perfetto di un evento unico. L’esperienza pluriennale nell’intrattenimento musicale insieme alla continua ricerca delle novità, ci spingono a rinnovarci costantemente e ad arricchire le nostre performance live per rendere unico il tuo evento!'},
        { property: 'og:description', content:  'Il sound perfetto di un evento unico. L’esperienza pluriennale nell’intrattenimento musicale insieme alla continua ricerca delle novità, ci spingono a rinnovarci costantemente e ad arricchire le nostre performance live per rendere unico il tuo evento!'},
        { property: 'twitter:description', content:  'Il sound perfetto di un evento unico. L’esperienza pluriennale nell’intrattenimento musicale insieme alla continua ricerca delle novità, ci spingono a rinnovarci costantemente e ad arricchire le nostre performance live per rendere unico il tuo evento!'},
        { property: 'og:title', content: "Dark Angel Eventi"},
        { property: 'twitter:title', content: "Dark Angel Eventi"},
        { property: 'og:site_name', content: 'Epiloge'},
        { property: 'og:type', content: 'website'},
        { property: 'twitter:card', content: 'summary_large_image'},
        { property: 'og:url', content: 'https://darkangeleventi.it/'},
        { property: 'twitter:url', content: 'https://darkangeleventi.it/'},
        { property: 'og:image', content: require('/src/assets/img/weddings_2.jpg')},
        { property: 'twitter:image', content: require('/src/assets/img/weddings_2.jpg')},
        { name: 'facebook-domain-verification', content: 'xsrpswszw9lt28znbhivmgahhnhl0a'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style>
#app {
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #131313;
  margin: 0;
  font-size: 18px;
}

p{
  line-height: 30px;
}

h1, h2, h3, h4, h5 {
  font-family: "ElMessiri Bold", sans-serif;
  font-weight: 400;
}

h5{
  font-size: 36px;
  line-height: 38px;
}

.color-white{
  color: white;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 30px;
}

@media screen and (max-width: 768px){
  .container.container-edgeable{
    padding: 0;
  }
}

.section-padding{
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-black{
  background-color: #131313;
  color: white;
}

.font-family-antipasto{
  font-family: "Antipasto Pro", sans-serif;
}

.font-family-messiri{
  font-family: "ElMessiri Bold", sans-serif;
}

.image-width-full{
  max-width: 100%;
}
</style>
