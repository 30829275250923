<template>
  <nav>
    <div class="logo">
      <img :src="require('/src/assets/img/logo_2022.png')" alt="DarkAngel Eventi">
    </div>
    <div class="sitemap">
      <ul>
        <template v-if="$route.name === 'home'">
          <li><span @click="scroll('#weddings')">Wedding</span></li>
          <li><span @click="scroll('#about-us')">Dicono di noi</span></li>
          <li><span @click="scroll('#live_social')">Live&Social</span></li>
          <li><span @click="scroll('#who-we-are')">Chi siamo</span></li>
          <li><span @click="scroll('#contact')">Contattaci</span></li>
        </template>
        <template v-else>
          <li><router-link to="/#weddings">Wedding</router-link></li>
          <li><router-link to="/#about-us">Dicono di noi</router-link></li>
          <li><router-link to="/#live_social">Live&Social</router-link></li>
          <li><router-link to="/#who-we-are">Chi siamo</router-link></li>
          <li><router-link to="/#contact">Contattaci</router-link></li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  methods: {
    scroll(elem){
      let h = document.querySelector('nav').offsetHeight;
      window.scrollTo({
        top: document.querySelector(elem).offsetTop - h,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  nav{
    background: #131313;
    padding: 10px 0;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  nav .logo img{
    max-width: 200px;
    padding: 10px 0;
  }

  .sitemap ul{
    list-style-type: none;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
  }

  li {
    min-width: 100px;
    margin-bottom: 5px;
  }

  @media screen and (min-width:768px){
    .sitemap ul {
      overflow-x: unset;
      justify-content: center;
    }
  }

  a, li span{
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #343434;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #131313;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #131313;
  }
</style>
