<template>
  <section id="features">
    <div class="container">

      <feature>
        <template v-slot:number>1000<span class="highlighted">+</span></template>
        <template v-slot:text>eventi in oltre 10 anni nel settore</template>
      </feature>

      <feature>
        <template v-slot:number>5/<span class="highlighted">5</span></template>
        <template v-slot:text>punteggio nelle recensioni di matrimonio.com</template>
      </feature>

      <feature>
        <template v-slot:number>500<span class="highlighted">+</span></template>
        <template v-slot:text>brani in un repertorio in continua evoluzione</template>
      </feature>

    </div>
  </section>
</template>

<script>
import Feature from "@/components/pages/home/Feature";
export default {
  name: "Features",
  components: {Feature}
}
</script>

<style scoped>
  #features{
    background: #131313;
    padding-bottom: 60px;
  }

  @media screen and (min-width: 768px){
    #features .container{
      display: flex;
    }
  }

</style>
