<template>
  <section>
    <navbar/>
    <hero/>
    <features/>
    <weddings/>
    <about-us/>
    <live-social/>
    <who-we-are/>
    <partners/>
    <band/>
    <contact/>
    <whats-app-cta/>
  </section>
</template>

<script>
import Hero from "@/components/pages/home/Hero";
import Features from "@/components/pages/home/Features";
import Weddings from "@/components/pages/home/Weddings";
import AboutUs from "@/components/pages/home/AboutUs";
import LiveSocial from "@/components/pages/home/LiveSocial";
import WhoWeAre from "@/components/pages/home/WhoWeAre";
import Partners from "@/components/pages/home/Partners";
import Band from "@/components/pages/home/Band";
import Contact from "@/components/pages/home/Contact";
import Navbar from "@/components/Navbar";
import WhatsAppCta from "@/components/pages/home/WhatsAppCta";
export default {
  name: "Home",
  components: {WhatsAppCta, Navbar, Contact, Band, Partners, WhoWeAre, LiveSocial, AboutUs, Weddings, Features, Hero}
}
</script>

<style scoped>

</style>
