<template>
  <section id="weddings">
    <div class="section-padding container">
      <main-title>Weddings</main-title>
      <p>L’esperienza pluriennale nell’intrattenimento musicale insieme alla continua
        ricerca delle novità, ci spingono a rinnovarci costantemente e ad arricchire le nostre performance live per
        rendere unico il tuo evento!</p>
    </div>
    <div class="container container-edgeable">
      <img :src="require('/src/assets/img/weddings_2.jpg')" alt="Weddings" class="image-width-full">
    </div>
    <div class="section-padding container">
      <p>
        Consapevoli di quanto la parte musicale sia fondamentale durante il ricevimento (da marito e moglie possiamo
        testimoniarlo! <router-link :to="{name: 'band', params: { slang: 'ado-angelica'}}">Ado&Angelica</router-link>), è essenziale per noi curare
        tutto nei dettagli, concordando insieme a voi le diverse soluzioni che vanno a formare il “menù” musicale che
        accompagna il giorno più bello della vostra vita, dal primo lento fino al taglio della torta nuziale, il tutto
        riproposto in un concept originale che mira ad una nuova esperienza di musica live:
        <br/><br/>
        Tutti gli strumenti della band sono wireless, consentendoci, durante le tranche di ballo più travolgenti, di
        suonare e cantare tra sposi e invitati, mixando i brani come se ci fosse un dj a far girare i dischi, creando
        così un vero coinvolgimento e trasporto collettivo in un mix di eleganza, energia e passione, con l’obiettivo
        di entusiasmare e rendere partecipi anche i vostri invitati più timidi, con l’accortezza di non risultare mai
        invadenti.
      </p>
    </div>
    <div class="flotted container container-edgeable">
      <div class="container container-edgeable">
        <img :src="require('/src/assets/img/weddings_1.jpg')" alt="Matrimoni" class="image-width-full">
      </div>
      <div class="section-padding container">
        <p>
          La “mission” targata Dark Angel è quella di sorprendere, emozionare, allietare e accompagnare voi sposi e i
          vostri ospiti, con le proposte musicali che preferite e con la libertà di scegliere la formula di
          intrattenimento che più vi piace, con la certezza che il giorno del vostro matrimonio sia il ricordo di un
          evento speciale e indimenticabile non solo per voi, ma per tutti i vostri amici e parenti.
        </p>
      </div>
    </div>
    <div class="container faqs--container">
      <faqs/>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";
import Faqs from "@/components/pages/home/Faqs";

export default {
  name: "Weddings",
  components: {Faqs, MainTitle}
}
</script>

<style scoped>
  @media screen and (min-width: 768px) {
    .flotted {
      display: flex;
    }

    .flotted div {
      flex: 1;
    }

    .faqs--container{
      margin-top: 60px;
    }
  }

  .image-width-full {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
</style>
