<template>
  <section id="who-we-are" class="section-padding section-black">
    <div class="container">
      <main-title>Chi siamo</main-title>
      <p>
        Sei diverse personalità musicali fondono stile, idee ed esperienze differenti dando vita al progetto "Dark
        Angel".
        Una moltitudine di generi che creano un vero e proprio "viaggio" nella musica, in un mix di eleganza, energia e
        passione, volto a suscitare la curiosità di un pubblico di ogni età, rendendo ogni evento unico e speciale.
        <br/><br/>
        Il vasto repertorio in continua evoluzione, suonato rigorosamente dal vivo e mixato come se girassero i dischi
        di
        un DJ, si adatta ad ogni tipologia di situazione musicale, unendo eleganza, energia e passione per ore di pure
        emozioni e divertimento.
        <br/><br/>
        Per maggiori informazioni sulla proposta musicale e delle idee per il tuo evento, contattaci!
      </p>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";

export default {
  name: "WhoWeAre",
  components: {MainTitle}
}
</script>

<style scoped>

</style>
