<template>
  <section id="live_social" class="section-padding">
    <div class="container">
      <main-title>Live&Social</main-title>
    </div>
    <div class="content">
      <template v-for="(video, index) in videos">
        <div v-if="index === active" :key="index" :class="{visible: index === active}" class="video">
          <template v-if="video.type === 'facebook'">
            <iframe
                :src="'https://www.facebook.com/plugins/video.php?href=' + video.href + '&width=500&show_text=false&appId=981303988590400&height=280'"
                width="100%" height="315" style="border:none;overflow:hidden"
                scrolling="no" frameborder="0" allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </template>
          <template v-else-if="video.type === 'youtube'">
            <iframe width="100%" height="315" :src="'https://www.youtube.com/embed/' + video.href"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </template>
        </div>
      </template>
      <div class="thumbs">
        <div class="thumb" v-for="(video_thumb, index) in videos" :key="index"
             @click="active = index"
             :style="{backgroundImage: 'url(' + video_thumb.thumb + ')'}"
             :class="{active: index === active}"
        >
        </div>
      </div>
      <div class="instagram--feed">
        <template v-for="(content, index) in this.medias">
          <a v-if="content.media_type !== 'VIDEO'" :key="index" :href="content.permalink" target="_blank" class="ig-elem">
            <div :style="{backgroundImage: 'url(' + content.media_url + ')'}"></div>
          </a>
          <a v-else :key="index" :href="content.permalink" target="_blank" class="ig-elem">
            <div :style="{backgroundImage: 'url(' + content.thumbnail_url + ')'}"></div>
          </a>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";

export default {
  name: "LiveSocial",
  components: {MainTitle},
  data() {
    return {
      active: 0,
      code: 'AQBoWqY9J2ddyg6JrD1vcvMn1vSQiFOV9RNdyXLKABbIe2ONUaZ6jNvhRqULmP-f8gSpqjKJtQVjtkI8PWtLv02bbGRFJJ7IqW9lezkgQuZdljwDWF2ZIjlP5Ny_2nTOY7dgIxitQhqyVmSTh-T2jTFbXZf196KHfoML98wQxUfVARWRaVfVosBpT4qA6rKBQw5_2WexMnlGXHVgKY1wBQXsrQcZnJc1WYe1v933Js5v3A#_',
      token: 'IGQVJVMHZASV1B6QTF1TlZAVNmZAULTlqNEtOM1kwZAWotTHJ2S1pucTBxUlkxcHMtZAzRxMXRwbE5kbE9rODgwMEJ6RzJyT1kzdW5URTJhRFZA1WUU3TjljdjE0VlhNaU5SSUJyZAGYwNEVKS0dBVzRZAQ3VBagZDZD',
      medias: [],
      videos: [
        {
          thumb: require('/src/assets/img/videos/video1.webp'),
          type: 'youtube',
          href: 'A3JQVt-LWis'
        },
        {
          thumb: require('/src/assets/img/videos/video2.webp'),
          type: 'youtube',
          href: 'GuPLdoFhmYU'
        },
        {
          thumb: require('/src/assets/img/videos/video3.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/625461781773383'
        },
        {
          thumb: require('/src/assets/img/videos/video4.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/477662879609818'
        },
        {
          thumb: require('/src/assets/img/videos/video5.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/156532775669909'
        },
        {
          thumb: require('/src/assets/img/videos/video6.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/1143158332804589'
        },
        {
          thumb: require('/src/assets/img/videos/video7.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/300345214167005'
        },
        {
          thumb: require('/src/assets/img/videos/video8.jpg'),
          type: 'facebook',
          href: 'https://www.facebook.com/DarkAngelEventi/videos/1230707550651614'
        }
      ]
    }
  },
  mounted() {

    const axios = require('axios');
    let medias = null
    axios.get('https://graph.instagram.com/v12.0/17841406331702808', {
      params: {
        fields: 'id,username,media',
        access_token: this.token
      }
    }).then(response => {
      medias = response.data.media.data
    }).then(() => {
      for (let media in medias.slice(0, 9)) {
        axios.get('https://graph.instagram.com/' + medias[media].id, {
              params: {
                fields: 'permalink,media_url,media_type,thumbnail_url',
                access_token: this.token
              }
            }
        ).then(response => {
          this.medias.push(response.data)
        })
      }
    });

  }
}
</script>

<style scoped>
iframe {
  max-width: 600px;
  width: 100%;
  height: 350px;
}

.video {
  display: none;
  margin: auto;
  text-align: center;
}

.video.visible {
  display: block;
}

.thumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.thumb {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  opacity: 0.5;
  cursor: pointer;
}

.thumb.active {
  opacity: 1;
}

.instagram--feed{
  margin: 60px auto 0;
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.instagram--feed a div{
  background-size: cover;
  background-position: center;
  padding-top: 100%;
}
</style>
