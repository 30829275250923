import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import Home from "@/components/pages/home/Home";
import Band from "@/components/pages/band/Band";
import VueMeta from "vue-meta";

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueMeta);


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/band/:slang',
    name: 'band',
    component: Band
  }
]



const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
