<template>
  <section id="contact" class="section-padding container">
    <main-title>Contattaci</main-title>
    <p>
      Se sei interessato al progetto Dark Angel per il tuo evento o se semplicemente hai delle curiosità, non esitare a
      contattarci tramite i recapiti sottostanti o direttamente attraverso il form mail qui accanto: il messaggio verrà
      recapitato immediatamente al nostro indirizzo e-mail.
    </p>
    <div class="form">
      <div class="flexed">
        <p>Cel.: +39 329 796 6921</p>
        <main-button href="tel:3297966921">Chiama ora</main-button>
      </div>
      <input-group type="text" name="name" required v-model="name" :disabled="status.disabled">Nome *</input-group>
      <input-group type="text" name="surname" required v-model="surname" :disabled="status.disabled">Cognome *</input-group>
      <input-group type="email" name="email" v-model="email" :disabled="status.disabled">Email *</input-group>
      <input-group type="tel" name="telephone" v-model="telephone" :disabled="status.disabled">Telefono *</input-group>
      <input-group type="text" name="city" v-model="city" :disabled="status.disabled">Città *</input-group>
      <input-group type="date" name="date" v-model="date" :disabled="status.disabled">Data evento (facoltativo)</input-group>
      <textarea-group name="message" v-model="message" :disabled="status.disabled">Messaggio *</textarea-group>
      <template v-if="status.sending">
        <main-button>Invio in corso...</main-button>
      </template>
      <template v-else>
        <main-button @click.native="submitForm">Invia</main-button>
      </template>
      <p v-if="status.error" v-html="status.error"></p>
      <p v-if="status.success">Messaggio inviato correttamente!</p>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";
import MainButton from "@/components/helpers/MainButton";
import InputGroup from "@/components/helpers/InputGroup";
import TextareaGroup from "@/components/helpers/TextareaGroup";

export default {
  name: "Contact",
  components: {TextareaGroup, InputGroup, MainButton, MainTitle},
  data() {
    return {
      name: null,
      surname: null,
      email: null,
      telephone: null,
      city: null,
      date: null,
      message: null,
      status : {
        error: null,
        success: null,
        sending: null,
        disabled: false
      },
    }
  },
  methods: {
    submitForm() {
      this.status.sending = true;

      const axios = require('axios');

      if (!this.name || !this.surname || !this.email || !this.telephone || !this.city || !this.message){
        this.status.error = 'Compila correttamente tutti i campi obbligatori.';
        this.status.sending = false;
        return ;
      }

      axios.get('https://us-central1-darkangel-email-sender.cloudfunctions.net/sendMail', {
        params: {
          name: this.name,
          surname: this.surname,
          email: this.email,
          telephone: this.telephone,
          city: this.city,
          date: this.date,
          message: this.message
        }
      }).then(() => {
        this.status.error = this.status.sending = null;
        this.status.disabled = this.status.success = true;
      })
    }
  },
  created() {

  }
}
</script>

<style scoped>
  .flexed {
    margin: 40px 0;
  }

  .form {
    max-width: 500px;
    margin: auto;
  }
</style>
