<template>
  <section id="band" class="section-padding section-black">
    <div class="container">
      <main-title>La Band</main-title>
    </div>
    <div class="container band--container">
      <template v-for="(element, index) in band">
        <router-link :to="{name: 'band', params: { slang: element.slang } }" :key="index">
          <div class="band-element"
               :style="{backgroundImage: 'url(' + element.img + ')'}"
               :class="{double: Array.isArray(element.artist)}"
          >
            <p class="role font-family-messiri">{{ element.role }}</p>
            <template v-if="Array.isArray(element.artist)">
              <div class="full_name" v-for="(full_name, i) in element.artist" :key="i">
                <p class="name font-family-messiri">{{ full_name.name }}</p>
                <p class="surname font-family-messiri">{{ full_name.surname }}</p>
              </div>
            </template>
            <template v-else>
              <div class="full_name">
                <p class="name font-family-messiri">{{ element.artist.name }}</p>
                <p class="surname font-family-messiri">{{ element.artist.surname }}</p>
              </div>
            </template>
          </div>
        </router-link>
      </template>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";

export default {
  name: "Band",
  components: {MainTitle},
  data() {
    return {
      band: [
        {
          slang: 'ado-angelica',
          role: 'voci',
          img: require('/src/assets/img/band/ado_e_ang.jpg'),
          artist: [
            {
              name: 'Ado',
              surname: 'Atzeni'
            },
            {
              name: 'Angelica',
              surname: 'Carnevale'
            }
          ]
        },
        {
          slang: 'vito-grazio-disanto',
          role: 'batteria',
          img: require('/src/assets/img/band/vito_grazio.jpg'),
          artist: {
            name: 'Vito Grazio',
            surname: 'Disanto'
          }
        },
        {
          slang: 'rocco-girardi',
          role: 'basso',
          img: require('/src/assets/img/band/rocco_girardi.jpg'),
          artist: {
            name: 'Rocco',
            surname: 'Girardi'
          }
        },
        {
          slang: 'nicholas-nuzzi',
          role: 'chitarra',
          img: require('/src/assets/img/band/nicholas_nuzzi.jpg'),
          artist: {
            name: 'Nicholas',
            surname: 'Nuzzi'
          }
        },
        {
          slang: 'giuseppe-cramarossa',
          role: 'tastiere e synth',
          img: require('/src/assets/img/band/giuseppe_cramarossa.jpg'),
          artist: {
            name: 'Giuseppe',
            surname: 'Cramarossa'
          }
        },
      ]
    }
  }
}
</script>

<style scoped>
.band--container {
  padding: 0;
  display: grid;
}

.band-element {
  width: 100%;
  max-width: 430px;
  padding-top: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.band-element::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 170px;
  background: -moz-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%); /* FF3.6+ */
  background: -webkit-gradient(linear, 1deg, color-stop(30%, 131313), color-stop(80%, 000000)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%); /* Opera 11.10+ */
  background: -ms-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%); /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
  background: linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%); /* W3C */
}

.band-element .role {
  color: #EB2020;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  font-size: 30px;
  margin: 0;
  max-width: 25%;
  text-align: right;
}

.band-element .full_name {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
  color: white;
}

.full_name .name {
  font-size: 30px;
  margin: 5px 0;
}

.full_name .surname {
  font-size: 50px;
  margin: 0;
}

.full_name .surname:first-letter {
  color: #EB2020;
}

.band-element.double .role {
  right: 0;
  left: 0;
  margin: auto;
  bottom: 120px;
  text-align: center;
}

.band-element.double .full_name .name {
  font-size: 28px;
}

.band-element.double .full_name .surname {
  font-size: 45px;
}

.band-element.double .full_name:nth-child(3) {
  left: unset;
  right: 30px;
  text-align: right;
}

@media screen and (min-width: 768px) {
  .band--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .band-element {
    flex: 0 0 50%;
    max-width: unset;
    padding-top: 100%;
  }
}
</style>
