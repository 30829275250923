<template>
  <div class="faqs--container">
    <header :class="{opened: show}" @click="show = !show">
      <main-title>Faq</main-title>
      <i class="fas fa-chevron-up"></i>
    </header>
    <div v-if="show" class="faqs--content">
      <div v-for="faq in faqs" :key="faq.question" class="faq">
        <h5>{{faq.question}}</h5>
        <p v-html="faq.answer"></p>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";

export default {
  name: "Faqs",
  components: {MainTitle},
  data() {
    return {
      show: false,
      faqs: [
        {
          question: 'Com\'è composta la band?',
          answer: 'Durante il pranzo o la cena è presente tutta la band in formazione completa: voce maschile e voce femminile, batteria, basso, chitarra, tastiere, sax*.'
        },
        {
          question: 'Qual è il vostro genere?',
          answer: 'Amiamo la musica a 360° in ogni suo stile ed epoca, per questo versatilità e nuove influenze sono la nostra più grande sfida, spaziando attraverso la dance music del nuovo e del vecchio millennio, il pop internazionale, gli intramontabili del rock, la new wave anni 80, il synth pop, le hit del momento, il divertentismo e cantautorato italiano, il twist & rockabilly, e tanto altro.\n' +
              '<br/><br/>' +
              'L’animazione e lo speakeraggio sono curati da noi cantanti [Ado e Angelica], che oltre ad essere le voci della band, ci occupiamo della conduzione dell’evento nella sua interezza.'
        },
        {
          question: 'Curate anche il fine festa?',
          answer: 'Certo, dal taglio torta...si cambia musica! Con il fine festa curato dalle selezioni musicali del nostro DJ!'
        },
        {
          question: 'E suonate tutto questo?',
          answer: 'Niente di preimpostato, perché il tutto è personalizzato in base ai vostri gusti musicali!<br/>' +
              'Per questo motivo è per noi fondamentale un incontro con voi per la definizione della scheda tecnica dell\'evento durante il quale, oltre a decidere i brani clou della giornata, abbiamo modo di conoscere i vostri generi musicali preferiti e il tipo di animazione più adatta al mood della festa.'
        },
        {
          question: 'Ma se il giorno dell\'evento avessi qualche richiesta non presente in repertorio?',
          answer: 'Don’t panic! Anche se siamo una live band abbiamo comunque la nostra consolle sempre con noi, per riprodurre in originale qualunque brano tu o i tuoi ospiti desideriate! '
        }
      ]
    }
  }
}
</script>

<style scoped>
  .faqs--container{
    padding-bottom: 60px;
    max-width: 800px;
    margin: auto;
  }

  header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  header .main-title{
    margin-bottom: 0;
  }

  header:not(.opened) i {
    transform: rotate(180deg);
    transition: all ease-out .5s;
  }

  h5{
    margin-bottom: 0;
  }
</style>
