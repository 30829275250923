<template>
  <footer class="section-padding section-black">
    <div class="container">
      <img :src="require('/src/assets/img/logo_2022.png')" alt="DarkAngel Eventi">
      <div id="socials">
        <a href="https://www.facebook.com/DarkAngelEventi" target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/dark_angel_eventi/" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.youtube.com/c/DarkAngelEventi" target="_blank">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
      <div id="site-map">
        <ul>
          <template v-if="$route.name === 'home'">
            <li><span @click="scroll('#hero')">Home</span></li>
            <li><span @click="scroll('#weddings')">Wedding</span></li>
            <li><span @click="scroll('#live_social')">Live&Social</span></li>
            <li><span @click="scroll('#who-we-are')">Chi siamo</span></li>
            <li><span @click="scroll('#contact')">Contattaci</span></li>
          </template>
          <template v-else>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/#weddings">Wedding</router-link></li>
            <li><router-link to="/#live_social">Live&Social</router-link></li>
            <li><router-link to="/#who-we-are">Chi siamo</router-link></li>
            <li><router-link to="/#contact">Contattaci</router-link></li>
          </template>
        </ul>
      </div>
      <a href="https://www.iubenda.com/privacy-policy/83874643" class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
      <p>Copyright © 2011 - {{ new Date().getFullYear() }} Darkangel</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterPage",
  methods: {
    scroll(elem){
      let h = document.querySelector('nav').offsetHeight;
      window.scrollTo({
        top: document.querySelector(elem).offsetTop - h,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  footer{
    text-align: center;
  }

  img{
    max-width: 200px;
    padding-bottom: 10px;
  }

  ul {
    padding: 0;
  }

  li, a {
    color: white;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
  }

  #socials {
    display: flex;
    justify-content: space-between;
    max-width: 250px;
    margin: auto;
    font-size: 1.4em;
  }

  #site-map {
    margin: 20px 0;
    line-height: 35px;
  }
</style>
