<template>
  <a href="https://wa.me/+393297966921" target="_blank">
    <img :src="require('/src/assets/img/wa.png')" alt="Contattaci tramite WhatsApp">
  </a>
</template>

<script>
export default {
  name: "WhatsAppCta"
}
</script>

<style scoped>
  a {
    position: fixed;
    right: 30px;
    bottom: 30px;
  }

  img{
    max-width: 50px;
  }
</style>
