<template>
  <section id="partners" class="section-black">
    <div class="container">
      <main-title>Partners</main-title>
    </div>
    <div class="container container-edgeable">
      <div class="partners--container">
        <a v-for="(partner, index) in partners" :key="index" :href="partner.url" target="_blank">
          <div class="partner" >
            <img :src="partner.img" :alt="partner.name">
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";
export default {
  name: "Partners",
  components: {MainTitle},
  data(){
    return {
      partners: [
        {
          name: 'Parco la serra',
          img: require('/src/assets/img/partners/parco_la_serra.png'),
          url: 'https://www.parcolaserra.com/'
        },
        {
          name: 'Tenula La Vallonea',
          img: require('/src/assets/img/partners/la_vallonea.png'),
          url: 'https://www.tenutalavallonea.it/'
        },
        {
          name: 'Villa Carafa',
          img: require('/src/assets/img/partners/villa_carafa.png'),
          url: 'https://www.villacarafa.com/'
        },
        {
          name: 'Mogliara',
          img: require('/src/assets/img/partners/mogliara.png'),
          url: 'https://www.mogliara.it/it/'
        },
        {
          name: 'Lucio Ciccarone',
          img: require('/src/assets/img/partners/lucio_ciccarone.png'),
          url: 'http://www.luciociccarone.it/'
        },
        {
          name: 'Matrimonio.com',
          img: require('/src/assets/img/partners/matrimonio.png'),
          url: 'https://www.matrimonio.com/'
        }
      ]
    }
  }
}
</script>

<style scoped>
  .partners--container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .partner{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 120px;
    margin: 15px;
  }

  .partner img{
    max-height: 120px;
    max-width: 200px;
    padding: 0 25px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #343434;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c2c1c1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
</style>
