<template>
  <div class="input-group">
    <label>
      <slot></slot><br/>
      <input :type="type" :name="name" v-model="content" :disabled="disabled">
    </label>
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  props: ['type', 'name', 'value', 'disabled'],
  data() {
    return {
      content: null
    }
  },
  created() {
    this.content = this.value
  },
  watch: {
    content() {
      this.$emit('input', this.content)
    }
  }
}
</script>

<style scoped>
  .input-group{
    margin: 15px 0;
  }

  input {
    border: none;
    background: #F2F2F2;
    border-radius: 4px;
    height: 55px;
    width: calc(100% - 40px);
    padding: 0 20px;
    font-size: 18px;
    margin: 10px 0;
  }
</style>
