<template>
  <div class="feature">
    <p class="number">
      <slot name="number"></slot>
    </p>
    <p class="description">
      <slot name="text"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "Feature",
}
</script>

<style scoped>
  .feature{
    color: white;
    padding: 20px;
    text-align: center;
  }

  .number{
    font-size: 4em;
    text-align: center;
    margin: 0;
    font-weight: 600;
  }

  .number .highlighted{
    color: #EB2020;
  }

  .description {
    font-size: 1.4em;
  }
</style>
