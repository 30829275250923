<template>
  <section id="about-us" class="section-padding">
    <div class="container">
      <main-title>Dicono di noi</main-title>
    </div>
    <div class="container container-edgeable">
      <div class="reviews--container">
        <div id="wp-widget-reviews">
          <div id="wp-widget-preview">
            Leggi <a href="https://www.matrimonio.com/musica-matrimonio/dark-angel-eventi--e156159/opinioni" rel="nofollow" >le nostre recensioni</a> a &nbsp;
            <a href='https://www.matrimonio.com' rel="nofollow">
              <img src="https://cdn1.matrimonio.com/assets/img/logos/gen_logoHeader.svg" height="20">
            </a>
          </div>
        </div>
      </div>
      <div class="winners--container">
        <div class="winners--holder">

          <div id="wp-ratedWA">
            <a target="_blank" href="https://www.matrimonio.com/musica-matrimonio/dark-angel-eventi--e156159" rel="nofollow" title="Dark Angel Eventi, vincitore Wedding Awards 2023 Matrimonio.com">
              <img width="125" height="125" alt="Dark Angel Eventi, vincitore Wedding Awards 2023 Matrimonio.com" id="wp-ratedWA-img-2023" src="https://cdn1.matrimonio.com/img/badges/2023/badge-weddingawards_it_IT.jpg">
            </a>
          </div>
          <div id="wp-ratedWA">
            <a target="_blank" href="https://www.matrimonio.com/musica-matrimonio/dark-angel-eventi--e156159" rel="nofollow" title="Dark Angel Eventi, vincitore Wedding Awards 2022 Matrimonio.com">
              <img width="125" height="125" alt="Dark Angel Eventi, vincitore Wedding Awards 2022 Matrimonio.com" id="wp-ratedWA-img-2022" src="https://cdn1.matrimonio.com/img/badges/2022/badge-weddingawards_it_IT.jpg">
            </a>
          </div>
          <div id="wp-ratedWA">
            <a target="_blank" href="https://www.matrimonio.com/musica-matrimonio/dark-angel-eventi--e156159" rel="nofollow" title="Dark Angel Eventi, vincitore Wedding Awards 2021 Matrimonio.com">
              <img width="125" height="125" alt="Dark Angel Eventi, vincitore Wedding Awards 2021 Matrimonio.com" id="wp-ratedWA-img-2021" src="https://cdn1.matrimonio.com/img/badges/2021/badge-weddingawards_it_IT.jpg">
            </a>
          </div>
          <div id="wp-ratedWA">
            <a target="_blank" href="https://www.matrimonio.com/musica-matrimonio/dark-angel-eventi--e156159" rel="nofollow" title="Dark Angel Eventi, vincitore Wedding Awards 2021 Matrimonio.com">
              <img width="125" height="125" alt="Dark Angel Eventi, vincitore Wedding Awards 2020 Matrimonio.com" id="wp-ratedWA-img-2020" src="https://cdn1.matrimonio.com/img/badges/2020/badge-weddingawards_it_IT.jpg">
            </a>
          </div>
        </div>
        <div>
          <p>Vincitori dei Wedding Awards 2023</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainTitle from "@/components/helpers/MainTitle";

export default {
  name: "AboutUs",
  components: {MainTitle},
  data() {
    return {
      reviews: [
        {
          text: '"Che dire dei Dark Angel? Semplcemente favolosi, strepitosi...Non abbiamo mai avuto dubbi nella scelta della band, ma il matrimonio ha in realtà superato le aspettative...Sono stati davvero eccezionali... Gli invitati continuano ancora a farci i complimenti per la loro bravura...Grazie di cuore per aver reso il nostro giorno più importante esattamente come lo desideravamo."',
          author: 'Roberta D.',
          date: '17 settembre 2021'
        },
        {
          text: '"Perfetti e coinvolgenti. Non ci sono altre parole per descrivere come sono stati: perfetti!!!! Tutti e dico tutti gli invitati si sono divertiti e hanno ballato fino a sera tarda! Sono stati meravigliosi in tutto! Grazie di cuore."',
          author: 'Arianna',
          date: '08 settembre 2021'
        },
        {
          text: '"Che bomba! Li abbiamo voluti a tutti i costi e per loro abbiamo anche cambiato data al matrimonio. Che dire dei dark angel? Unici! Mix di professionalità, empatia, ottima musica e allegria. I nostri ospiti ci hanno fatto tutti i complimenti per la scelta. Ci rivediamo sicuramente al primo live disponibile. Un abbraccio Rita e Daniele"',
          author: 'Daniele D.',
          date: '08 ottobre 2021'
        },
        {
          text: '"Assolutamente top. Non esiste band migliore in circolazione! Di bella presenza, educati, composti, in completa sintonia fra loro ma soprattutto competenti e completi! OCsa non da poco! Abbiamo vissuto un magnifico matrimonio con la loro animazione, gli ospiti erano estasiati dalla loro musica e dalla loro bravura. Umanamente sono delle persone fantastiche, sensibili e molto disponibili. Li adoro! Bravi, continuate così!"',
          author: 'Francesca',
          date: '09 agosto 2021'
        },
        {
          text: '"Grandiosi, strepitosi. Abbiamo scelto i Dark Angel per il nostro matrimonio e scelta migliore non potevamo fare. Ci hanno supportato anche durante la pandemia. Angelica e Ado hanno delle voci meravigliose e tutti i ragazzi sono superlativi. Hanno fatto ballare anche i più restii. È stato tutto magico. Grazie ancora."',
          author: 'Claudia',
          date: '11 luglio 2021'
        },
        {
          text: '"Superlativi! Non potevamo scegliere band più appropriata ai nostri gusti musicali. Eleganti alla vista, voci che trasmettono la giusta carica, con loro il divertimento è assicurato, adrenalina pura. Che dire, grandi!"',
          author: 'Teresa',
          date: '01 ottobre 2021'
        },
        {
          text: '"Grandissimi! Se il nostro matrimonio è stato perfetto, il merito è anche dei Dark Angel, repertorio da paura, tecnicamente fortissimi e sopratutto per niente invadenti."',
          author: 'Ivan F.',
          date: '06 marzo 2021'
        },
        {
          text: '"Indimenticabili! Una sola parola per descriverli: fantastici. La riorganizzazione dell\'evento in tempo di covid è stata difficile, ma  anche grazie a loro se siamo riusciti a sposarci. Sono stati super disponibili, immediati nelle risposte e ci sono venuti incontro in tutti i modi. Sono stati anche incoraggianti e di supporto. Per non parlare della festa: una bomba! Ci siamo scatenati come matti, la pista era sempre piena ed abbiamo ricevuto tantissimi complimenti da parte degli invitati. Canzoni di tutti i generi, cantate e suonate dal vivo, anche recentissime, hanno accontentato tutti. I Dark Angel sono una garanzia e noi vogliamo ringraziarli di cuore per aver reso il nostro giorno indimenticabile."',
          author: 'Daniele D.',
          date: '19 settembre 2020'
        },
        {
          text: '"Superlativi. I Dark Angel sono eleganti sotto tutti i punti di vista, per l\'abbigliamento, per la selezione musicale, per il modo in cui si presentano. Hanno suonato dal vivo tutto il tempo e in tutte le location che abbiamo cambiato durante l\'evento. I Dark Angel sono divertenti e mai eccessivi, i nostri invitati si sono divertiti tutto il tempo. Sono professionali, disponibili e attenti alle richieste degli sposi. Sono bravi, perché cantano e suonano alla grande. Credo non esista di meglio. Se il nostro matrimonio è stato al di sopra delle aspettative è anche grazie a voi. Consigliatissimi!"',
          author: 'Mariantonietta L.',
          date: '04 settembre 2020'
        }
      ]
    }
  }
}
</script>

<style scoped>
section {
  background: #F6F6F6;
}

.reviews--container{
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  background: white;
}

.winners--container{
  text-align: center;
  margin: 60px 0 0 0;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.winners--holder {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.winners--container p {
  font-size: 1.5em;
  margin: 0;
  font-weight: bold;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.76);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}
</style>
