<template>
  <button v-if="scroll_to" class="main-button" @click="scroll()">
    <slot></slot>
  </button>
  <router-link v-else-if="route_name" :to="route_name">
    <button class="main-button">
      <slot></slot>
    </button>
  </router-link>
  <a v-else :href="href" class="main-button">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "MainButton",
  props: ['scroll_to', 'route_name', 'params', 'href'],
  methods: {
    scroll(){
      window.scrollTo({
        top: document.querySelector(this.scroll_to).offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
  button, a {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0 25px;
    min-width: 250px;
    background: #EB2020;
    border-radius: 4px;
    color: white;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    border: none;
    text-decoration: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=6, Direction=0, Color=#000000)";/*IE 8*/
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,0.2);/*FF 3.5+*/
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.2);/*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);/* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=6, Direction=135, Color=#000000); /*IE 5.5-7*/
  }
</style>
