<template>
  <section>
    <figure :style="{backgroundImage: 'url(' + selected.img + ')'}"
            class="band-element"
            :class="{double: Array.isArray(selected.artist)}">
      <div class="container">
        <i class="fas fa-arrow-left" @click="$router.go(-1)"></i>
        <div>
          <p class="role font-family-antipasto">{{ selected.role }}</p>
          <template v-if="Array.isArray(selected.artist)">
            <div class="full_name" v-for="(full_name, i) in selected.artist" :key="i">
              <p class="name font-family-antipasto">{{ full_name.name }}</p>
              <p class="surname font-family-antipasto">{{ full_name.surname }}</p>
            </div>
          </template>
          <template v-else>
            <div class="full_name">
              <p class="name font-family-antipasto">{{ selected.artist.name }}</p>
              <p class="surname font-family-antipasto">{{ selected.artist.surname }}</p>
            </div>
          </template>
        </div>
      </div>

    </figure>
    <div class="section-padding container">
      <p v-html="selected.text"></p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Band",
  data() {
    return {
      selected: null,
      band: [
        {
          slang: 'ado-angelica',
          role: 'voci',
          img: require('/src/assets/img/band/ado_e_ang.jpg'),
          artist: [
            {
              name: 'Ado',
              surname: 'Atzeni'
            },
            {
              name: 'Angelica',
              surname: 'Carnevale'
            }
          ],
          text: 'Iniziano la loro attività live nei locali della provincia di Bari a partire dal 2005, denominando il duo DARK ANGEL, volutamente al singolare, perché simbolo della fusione di due diverse personalità e timbriche vocali in un unico stile.<br/><br/>' +
              'La voglia di conoscere nuove realtà musicali li porta ad esibirsi in contesti musicali sempre differenti, collaborando dapprima con Valtur nei villaggi di Favignana, Ostuni, Sharm El Sheik, Mauritius etc., e per MSC Crociere in Sud Africa. Di qui l\'opportunità di proporre il proprio show “Flying with Music” in Namibia (Africa).<br/><br/>' +
              'Angelica unisce la passione per il canto a quella per le lingue, specializzandosi come interprete e traduttrice, garantendo un intrattenimento per un pubblico internazionale.<br/><br/>' +
              'Ado inizia i suoi studi di pianoforte classico presso il Conservatorio "N. Piccinni" di Bari e consegue nel 2020 la laurea in canto pop rock presso il Conservatorio "Nino Rota" di Monopoli BA.<br/><br/>' +
              'Parallelamente a un percorso ricco di impegni nel live entertainment, prendono parte ad alcuni format televisivi quali L’AIA – SUONI E DANZE DEL MEDITERRANEO (Telenorba), CULTURA MODERNA (Canale 5), X FACTOR (Rai Due).<br/><br/>' +
              'A seguito della continua ricerca ed evoluzione di nuovi stili musicali, nel 2009 fondano la band #DarkAngel.<br/><br/>'
        },
        {
          slang: 'vito-grazio-disanto',
          role: 'batteria',
          img: require('/src/assets/img/band/vito_grazio.jpg'),
          artist: {
            name: 'Vito Grazio',
            surname: 'Disanto'
          },
          text: 'Nato nel 1984, inizia a studiare la batteria all’età di 17 anni.Parallelamente alle prime esperienze live, studia per l’esame di ammissione al Conservatorio “E.R. Duni” di Matera, dove si laurea con ottimi voti per il corso “strumenti a percussione”, conseguendo in seguito anche la laurea di secondo livello.<br/><br/>' +
              'Frequenta diverse Master Class con Gregg Bisonette, Maurizio dei Lazaretti, Giuseppe Berlen, Frederic Macarez, in simultanea ad un’intensa attività live che lo vede coinvolto in diversi tour fra l’Italia e l’estero, assieme ad Antonio da Costa , Orchestra della magna Grecia, Orchestra di Puglia e Basilicata, Vito Nicola Paradiso, Tony Esposito, e tanti altri.<br/><br/>' +
              'Dopo aver collaborato con alcune scuole di musica del territorio barese, fonda del 2019 “La Casa delle Percussioni” (corsi propedeutici) ed è attualmente insegnante presso alcuni istituti ad indirizzo musicale.<br/><br/>' +
              'Le sue influenze variano dall’hard rock al progressive, passando per il british pop, latin.<br/><br/>' +
              'Con all’attivo più di 500 live fra Italia, Svizzera e Germania, nel 2013 si unisce al progetto #DarkAngel<br/><br/>'
        },
        {
          slang: 'rocco-girardi',
          role: 'basso',
          img: require('/src/assets/img/band/rocco_girardi.jpg'),
          artist: {
            name: 'Rocco',
            surname: 'Girardi'
          },
          text: 'Nato nel 1987, inizia a studiare il basso elettrico all’età di 17 anni.<br/><br/>' +
              'Muove i primi passi nella scena musicale riproponendo, assieme a una band locale, un repertorio rock anni 60/70 di cui è fortemente appassionato e che influenza maggiormente il suo stile.<br/><br/>' +
              'In seguito, oltre a numerose collaborazioni con tantissime formazioni pop e rock della scena musicale barese, è bassista, compositore e arrangiatore nel progetto Lanificio 52 con il quale pubblica 2 ep, sigla un contratto con la Sony Music Italy, partecipa ad una tappa del Battiti Live Tour e apre concerti di artisti quali Tiromancino, Marina Rei, Alexia, Saule, Mario Venuti, Extrema, Rezophonic, Mariella Nava e tanti altri.<br/><br/>' +
              'Le sue influenze musicali attraversano diversi stili quali vintage rock, indie, elettronica, progressive, british, pop, dub reggae e funk music.<br/><br/>' +
              'Con all’attivo più di 500 live in Italia, nel 2016 entra a far parte del progetto #DarkAngel<br/><br/>'
        },
        {
          slang: 'nicholas-nuzzi',
          role: 'chitarra',
          img: require('/src/assets/img/band/nicholas_nuzzi.jpg'),
          artist: {
            name: 'Nicholas',
            surname: 'Nuzzi'
          },
          text: 'Nato nel 1991, dimostra sin da bambino una grande attitudine musicale iniziando a suonare la chitarra all’età di 9 anni.<br/><br/>' +
              'Assieme ad alcuni coetanei fonda, all’età di 13 anni, la sua prima band di stampo pop rock che affiancava cover internazionali a brani inediti, curandone scrittura e arrangiamenti.<br/><br/>' +
              'Parallelamente alle prime esperienze live iniziate a 14 anni, studia chitarra classica e consegue la laurea in chitarra jazz presso il Conservatorio “Niccolò Piccinini” di Bari.<br/><br/>' +
              'Oltre alla collaborazione con diverse situazioni musicali di tipo rock, pop e dance della scena musicale barese, dal 2014 svolge l’attività di insegnante di chitarra presso varie accademie e scuole di musica private.<br/><br/>' +
              'I suoi generi di riferimento spaziano dal pop al rock, blues, progressive, funk.<br/><br/>' +
              'Con all’attivo oltre 500 live entertainment, nel 2017 prende parte al progetto #DarkAngel.<br/><br/>'
        },
        {
          slang: 'giuseppe-cramarossa',
          role: 'tastiere e synth',
          img: require('/src/assets/img/band/giuseppe_cramarossa.jpg'),
          artist: {
            name: 'Giuseppe',
            surname: 'Cramarossa'
          },
          text: 'Nato nel 1987, sviluppa sin da bambino una forte passione per la musica, iniziando lo studio dello strumento all’età di 6 anni.<br/><br/>' +
              'Inizia il suo percorso musicale suonando le tastiere in alcune band della scena musicale barese, sviluppando sempre più interesse e curiosità per l’elettronica e la ricerca del suono.<br/><br/>' +
              'Musicista poliedrico, in seguito unisce, a quella del Piano e dei Synth, anche la passione per il Flauto Traverso, avviando nel 2001 un percorso di studi presso il Conservatorio “N. Piccinni” di Bari, presenziando per 3 anni nell’orchestra giovanile del Conservatorio sotto la direzione del Maestro Pannarale.<br/><br/>' +
              'La sua attitudine nella miscelazione dei suoni e l’approfondita conoscenza dei sintetizzatori lo porta a collaborare con numerosissime situazioni musicali nell’ambito del live entertainment, affiancando specialmente artisti di genere dance & pop in vari circuiti di musica dal vivo (disco club, piazze, ecc.).<br/><br/>' +
              'Le sue influenze musicali variano dal pop al vintage rock, alla dance music, elettronica, funk e dub.<br/><br/>' +
              'Con all’attivo oltre 600 live in Italia, nel 2013 prende parte al progetto #DarkAngel<br/><br/>'
        },
      ]
    }
  },
  beforeMount() {
    for (let el in this.band){
      if (this.band[el].slang === this.$route.params.slang) {
        this.selected = this.band[el];
        break
      }
    }
  }
}
</script>

<style scoped>
  figure{
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 280px;
    margin: 0;
    position: relative;
  }

  figure:after{
    content: '';
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  figure i{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    color: white;
    background-color: rgba(0,0,0,0.5);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  figure .content{
    position: absolute;
    z-index: 2;
  }

  figure .container{
    position: relative;
    height: inherit;
  }

  .band-element .role {
    color: #EB2020;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    font-size: 30px;
    margin: 0;
    max-width: 25%;
    text-align: right;
  }

  .band-element .full_name {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 1;
    color: white;
  }

  .full_name .name {
    font-size: 30px;
    margin: 5px 0;
  }

  .full_name .surname {
    font-size: 50px;
    margin: 0;
  }

  .full_name .surname:first-letter {
    color: #EB2020;
  }

  .band-element.double .role {
    right: 0;
    left: 0;
    margin: auto;
    bottom: 120px;
    text-align: center;
  }

  .band-element.double .full_name .name {
    font-size: 28px;
  }

  .band-element.double .full_name .surname {
    font-size: 45px;
  }

  .band-element.double .full_name:nth-child(3) {
    left: unset;
    right: 30px;
    text-align: right;
  }
</style>
