<template>
  <section id="hero" class="color-white">

    <video loop autoplay muted playsinline>
      <source :src="require('/src/assets/video/hero.mp4')" type="video/mp4">
      Your browser does not support the video tag.
    </video>

    <div class="content">
      <h1 class="font-family-messiri">Il sound perfetto di un evento unico.</h1>
      <main-button scroll_to="#contact">Contattaci</main-button>
    </div>
  </section>
</template>

<script>
import MainButton from "@/components/helpers/MainButton";
export default {
  name: "Hero",
  components: {MainButton}
}
</script>

<style scoped>

  #hero{
    position: relative;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  #hero::before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0,0,0,0.4);
  }

  #hero::after{
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: -2px;
    margin: auto;
    width: 100%;
    height: 170px;
    background: -moz-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%);/* FF3.6+ */
    background: -webkit-gradient(linear, 1deg, color-stop(30%, 131313), color-stop(80%, 000000));/* Chrome,Safari4+ */
    background: -webkit-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%);/* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%);/* Opera 11.10+ */
    background: -ms-linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%);/* IE10+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    background: linear-gradient(1deg, #131313 30%, rgba(0, 0, 0, 0) 80%);/* W3C */
  }

  .content{
    position: relative;
    z-index: 2;
    height: 200px;
    text-align: center;
  }

  .content h1{
    font-weight: 400;
    font-size: 40px;
  }

  .content .main-button {
    margin: auto;
  }

</style>
