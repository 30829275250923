<template>
  <div class="input-group">
    <label>
      <slot></slot><br/>
      <textarea :name="name" v-model="message" :disabled="disabled">
      </textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: "TextareaGroup",
  props: ['name', 'value', 'disabled'],
  data() {
    return {
      message: null
    }
  },
  created() {
    this.message = this.value
  },
  watch: {
    message() {
      this.$emit('input', this.message)
    }
  }
}
</script>

<style scoped>
.input-group{
  margin: 15px 0;
}

textarea {
  border: none;
  background: #F2F2F2;
  border-radius: 4px;
  height: 100px;
  width: calc(100% - 40px);
  padding: 0 20px;
  font-size: 18px;
  margin: 10px 0;
}
</style>
