<template>
  <h3 class="main-title font-family-messiri">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped>
  h3{
    font-weight: 400;
    font-size: 53px;
    margin-top: 0;
    text-align: left;
  }

  h3::first-letter{
    color: #EB2020;
  }
</style>
